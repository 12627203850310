import React, { useState } from 'react';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { Link, navigate } from 'gatsby';
import { confirmPasswordReset } from 'firebase/auth';

import SEO from '../../components/SEO';
import Auth, { Group } from '../../components/Auth';
import { auth } from '../../utils/fb';

export default function ResetPassword(props: any) {
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
    error: '',
    success: false,
  });

  const handleChange = (e: any) => setValues({ ...values, [e.target.name]: e.target.value });

  let oob = props?.location?.href?.split('&')[1].slice(8);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setValues({ ...values, error: '', success: false });
    try {
      if (values.password !== values.confirmPassword) {
        setValues({ ...values, error: 'Password must match' });
        return;
      }
      if (values.password.length < 6) {
        setValues({ ...values, error: 'Password must be at least six characters long' });
        return;
      }
      await confirmPasswordReset(auth, oob, values.password);
      setValues({ password: '', confirmPassword: '', error: '', success: true });
      navigate('/auth/login');
    } catch (err) {
      setValues({ ...values, error: err.message, success: false });
    }
  };
  return (
    <Auth title="Change Password" subTitle="Please set a new password">
      <SEO title="Change Password" />
      <form onSubmit={handleSubmit}>
        <InputGroup fullWidth>
          <input
            type="password"
            placeholder="New Password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
        </InputGroup>
        <InputGroup fullWidth>
          <input
            type="password"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
          />
        </InputGroup>
        <Button status="Success" type="submit" shape="SemiRound" fullWidth>
          Change Password
        </Button>
        {values.error && <p>{values.error}</p>}
      </form>
      <Group>
        <Link to="/auth/login">Back to Log In</Link>
        <Link to="/auth/register">Register</Link>
      </Group>
    </Auth>
  );
}
